import { HTTP } from '../../axios/axios'
import _ from 'lodash'
let data

async function getAll (lang) {
  try {
    const res = await HTTP.get('/events', { headers: { 'accept-language': lang } })
    data = res.data
    for (const i of data) {
      i.emails = i.emails.split(',')
    }
    return data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (event, lang) {
  event.lang = lang
  const item = _.cloneDeep(event)
  item.emails = event.emails.join(',')
  return await HTTP.post('/events', item)
}
async function update (event, id) {
  const item = _.cloneDeep(event)
  item.emails = event.emails.join(',')
  return await HTTP.patch('/events/' + id, item)
}
async function deleteOne (event) {
  return await HTTP.delete('/events/' + event._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/events/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
